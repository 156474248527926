import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Header from "../components/Header/header"
import SimpleMap from '../components/GoogleMap/map'
import Form from "../components/Form/Form"
import HeroImage from "../components/HeroImage/heroImage"
import image from "../assets/images/wagon.jpg"

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact Us"
      description="Contact details for CNR Engineering. Get in touch for more information or a quote from our specialists."
    />
    <Header isHeaderSolid />
    <HeroImage title="Get in touch" subtitle="Contact" image={image} position="center bottom" padding="150px 0 90px" />
    <main className="contact">
      <div className="page-width">
        <div className="contact__inner">
          <div className="contact__inner_left">
            <h2>CNR Engineering</h2>
            <p><a href="mailto:richard@cnrengineering.co.uk"><svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ababab" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>richard@cnrengineering.co.uk</a></p>
            <p><a href="tel:01995238096"><svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#ababab" d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/></svg>01995 238 096</a></p>
            <p><a href="tel:07860536741"><svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path fill="#ababab" d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path></svg>07860 536 741</a></p>
          </div>
          <div className="contact__inner_right">
            <h2>CNR Precision</h2>
            <p><a href="mailto:paul@cnrengineering.co.uk"><svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ababab" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>paul@cnrengineering.co.uk</a></p>
            <p><a href="tel:01995238096"><svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#ababab" d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/></svg>01995 238 096</a></p>
            <p><a href="tel:07860334105"><svg className="sidebar__title-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="#ababab" d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path></svg>07860 334 105</a></p>
          </div>
        </div>
      </div>
      <div className="form-container">
        <div className="page-width">
          <h3>Get in touch</h3>
          <p>For more information on any of our services or information regarding our fleet please use the contact form below.</p>
          <Form id="contact_form" classToShow="form contact-form" />
        </div>
      </div>
      <div className="gmap">
        <SimpleMap />
      </div>
    </main>
  </Layout>
)

export default ContactPage
