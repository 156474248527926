import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import "./map.scss"

const AnyReactComponent = ({ text }) => <svg className="gmap__marker" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#00448b" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
const mapStyles = require('./mapStyle.json')

class SimpleMap extends Component {

  static defaultProps = {
    center: {
      lat: 53.888990,
      lng: -2.850360
    },
    zoom: 8,
    styles: mapStyles
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="gmap__map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCl-HWY0sqfXZI3Mx9L0R_w6VjyHwXlS0w' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          defaultOptions={{ styles: mapStyles }}
        >
          <AnyReactComponent
            lat={53.888249}
            lng={-2.856700}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
