import React from "react"
import { navigate } from 'gatsby-link'
import "./form.scss"

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

class Form extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isValidated: false
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error))
    }

    render() {
        const id = this.props.id;
        const classToShow = this.props.classToShow;
        return (
            <form
                id={id}
                className={classToShow}
                name="contact-page-form"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
            >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="contact-page-form" value="contact-page-form" />
                <div hidden>
                    <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                    </label>
                </div>
                <div className="form-col__half">
                    <div className="form-label-group">
                        <input
                            id={'firstName'}
                            name={'first-name'}
                            type="text"
                            placeholder="First name"
                            required
                            onChange={this.handleChange}
                        />
                        <label className="form_label" htmlFor="firstName">First name</label>
                    </div>
                    <div className="form-label-group">
                        <input
                            id={'lastName'}
                            name={'last-name'}
                            type="text"
                            placeholder="Last name"
                            required
                            onChange={this.handleChange}
                        />
                        <label htmlFor="lasttName">Last name</label>
                    </div>
                </div>
                <div className="form-col__half">
                    <div className="form-label-group">
                        <input
                            id={'telephone'}
                            name={'telephone'}
                            type="tel"
                            placeholder="Telephone"
                            required
                            onChange={this.handleChange}
                        />
                        <label htmlFor="telephone">Telephone</label>
                    </div>
                    <div className="form-label-group">
                        <input
                            id={'email'}
                            name={'email'}
                            type="email"
                            placeholder="Email"
                            required
                            onChange={this.handleChange}
                        />
                        <label htmlFor="email">Email</label>
                    </div>
                </div>
                <div className="form-label-group">
                    <textarea
                        id={'comment'}
                        name={'comment'}
                        placeholder="Comment"
                        required
                        onChange={this.handleChange}
                    />
                    <label htmlFor="comment">Comment</label>
                </div>
                <input type="submit" className="contact-form__button ripple" />
            </form>
        );
    }
}

export default Form
